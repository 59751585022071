'use client';

import { toast } from 'react-toastify';
import Image from 'next/image';
import Link from 'next/link';

export default function HomepageCard({
  title,
  bgImage,
  fgImage,
  url = '/',
  underConstruction = false,
  children,
}) {
  const isExternal = url.startsWith('http');
  return (
    <Link
      href={underConstruction ? '#' : url}
      className="overflow-hidden relative m-4 max-w-lg md:w-lg shadow-xl bg-cover bg-center min-h-32 transform duration-500 hover:-translate-y-4 cursor-pointer group md:w-[500px] max-w-[500px]"
      {...(isExternal && { target: '_blank', rel: 'noopener noreferrer' })}
      onClick={(e) => {
        if (underConstruction) {
          toast.warning(`${title} under construction`);
        }
      }}
    >
      {/* Background Image */}
      <Image src={bgImage} alt={title} layout="fill" objectFit="cover" />

      {/* Darkening Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-20 transition-opacity duration-300 group-hover:bg-opacity-75" />

      {/* Foreground Image Container */}
      <div className="absolute right-0 bottom-0 w-1/2 h-full transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 overflow-hidden">
        {/* Foreground Image */}
        <div
          className="w-full h-full bg-no-repeat bg-bottom bg-contain"
          style={{ backgroundImage: `url(${fgImage})`, transform: 'scaleX(-1)' }}
        >
          {/* Optional content here */}
        </div>
      </div>

      {/* Text Content */}
      <div className="w-full relative z-10 min-h-150 flex flex-wrap flex-col pt-96">
        <div className="w-full px-10">
          <h1 className="font-display font-bold text-white text-3xl mb-5 uppercase">{title}</h1>
          <div className="w-16 h-2 bg-orange-500 rounded-full mb-5"></div>
        </div>
        <div className="pl-10 w-full bg-black bg-opacity-80 opacity-0 text-white text-xl group-hover:opacity-80 transition-opacity duration-500 font-sans font-light p-1">
          {children}
        </div>
      </div>
    </Link>
  );
}
